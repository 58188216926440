import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    SET_PARKBOX_LOG_LEVEL_MESSAGE_CODE
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"


// NOTE:    
const generateLogTagOptions = () => (
    [
        "*",
        "APP",
        "Flash",
        "Main",
        "ETHERNET",
        "SOCKET",
        "SORT DATA ",
        "Smart Config",
        "JSON Data ",
        "RELAY Data ",
        "HTTP Data ",
        "OTA",
        "UART_TASK",
        "PARKBOX_STATUS",
        "INPUT_TASK_TAG",
        "PROCESS_FASTAG",
        "SITE_PASS",
        "SITE_PASS_LOG",
        "HTTP_CLIENT_T",
        "BARRIER_DEBUG",
        "REMOTE_LOG_TAG"
    ]
)


const generateLogLevelOptions = () => (
    [
        { value: "ESP_LOGI", title: "Information" },
        { value: "ESP_LOGE", title: "Error" },
        { value: "ESP_LOGW", title: "Warning" },
        { value: "ESP_LOGD", title: "Debug" },
        { value: "ESP_LOGV", title: "Verbose" },
        { value: "ESP_LOGN", title: "None" }
    ]
)


const SetParkboxLogLevel = ({ topic }) => {

    const client = useMqttClientContext()

    const logTagInputOptions = generateLogTagOptions()
    const [logTagInput, setLogTagInput] = useState(logTagInputOptions[0])

    const logLevelInputOptions = generateLogLevelOptions()
    const [logLevelInput, setLogLevelInput] = useState(logLevelInputOptions[0])


    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: SET_PARKBOX_LOG_LEVEL_MESSAGE_CODE,
            message_body: {
                "LOG_TAG": logTagInput,
                "LOG_LEVEL": logLevelInput.value,
            },
        }
    }


    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <>
            <Autocomplete
                options={logTagInputOptions}
                getOptionLabel={option => option}
                style={{ width: 200 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="normal"
                        label="Log Tag"
                        variant="outlined"
                    />
                )}
                value={logTagInput}
                onChange={(event, value) => {
                    setLogTagInput(value)
                }}
                disableClearable
            />

            <Autocomplete
                options={logLevelInputOptions}
                getOptionLabel={option => option.title}
                // NOTE: TODO:
                getOptionSelected={(option, value) => option.title === value.title}
                style={{ width: 200 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="normal"
                        label="Log Level"
                        variant="outlined"
                    />
                )}
                value={logLevelInput}
                onChange={(event, value) => {
                    setLogLevelInput(value)
                }}
                disableClearable
            />
        </>
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}

            inputFields={inputFields}
        />
    )
}


export default SetParkboxLogLevel