

const UPDATE_CHECKBOXES = "UPDATE_CHECKBOXES"
const ADD_NEW_KEY = "ADD_NEW_KEY"


const updateCheckboxes = ({ name, checked}) => {
    return {
        type: UPDATE_CHECKBOXES,
        name,
        checked,
    }
}

const addNewKey = ({ name }) => {
    return {
        type: ADD_NEW_KEY,
        name,
    }
}


export {
    updateCheckboxes,
    UPDATE_CHECKBOXES,
    addNewKey,
    ADD_NEW_KEY,    
}