import React from "react"
import { connect } from "react-redux"

import { enableDisablePingMessage } from "../../redux/mqttMessages/MessageActions"

import { withStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"
import Checkbox from "@material-ui/core/Checkbox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import Favorite from "@material-ui/icons/Favorite"
import FavoriteBorder from "@material-ui/icons/FavoriteBorder"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"


const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)


const EnableDisablePing = (props) => {
  
  const handleChange = (event) => {
    props.enableDisablePingMessage(event.target.checked)
  }
  

  return (
    <>
      <FormControlLabel
        control={
          <GreenCheckbox
            checked={props.showPingMessages}
            onChange={handleChange}
            name="checkedG"
          />
        }
        label="Show Ping"
      />
    </>
  )
}


const mapStateToProps = (state, ownProps) => {
  const { showPingMessages } = state.messageReducer
  return { showPingMessages }
}

const mapDispatchToProps = { enableDisablePingMessage }


export default connect(mapStateToProps, mapDispatchToProps)(EnableDisablePing)