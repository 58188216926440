import React, { useEffect } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    IS_ALIVE_MESSAGE_CODE,
    generateCurrentEpocTimeInSeconds
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'



const IsAlive = ({ topic }) => {

    const client = useMqttClientContext()

    useEffect(() => {
        handleSend()

        return () => {
            
        }
    }, [])


    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: IS_ALIVE_MESSAGE_CODE,
            message_body: "",
            message_time: generateCurrentEpocTimeInSeconds(),
        }
    }

    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}
        />
    )
}


export default IsAlive