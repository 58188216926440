import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    GET_BANK_KEY_MESSAGE_CODE
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"



const GetBankKey = ({ topic }) => {

    const client = useMqttClientContext()

    const [issuerKeyInput, setIssuerKeyInput] = useState('')
    const [vendorIdInput, setVendorIdInput] = useState('')

    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: GET_BANK_KEY_MESSAGE_CODE,
            message_body: {
                "ISSUER_KEY": issuerKeyInput,
                "VENDOR_ID": vendorIdInput,
            },
        }
    }

    // NOTE:    The below two functions are for validation purposes.(to allow only integer values)
    const handleSetIssuerKeyInput = value => {
        const regex = /^[0-9]+$/g;

        if (value === '' || regex.test(value)) {
            setIssuerKeyInput(value)
        } 
    }

    const handleSetVendorIdInput = value => {
        const regex = /^[0-9]+$/g;

        if (value === '' || regex.test(value)) {
            setVendorIdInput(value)
        } 
    }

    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <>
            <TextField
                id="standard-basic"
                label="Issuer Key"
                multiline={true}
                value={issuerKeyInput}
                onChange={event => handleSetIssuerKeyInput(event.target.value)}
                placeholder={"only int values..."}
            />

            <TextField
                id="standard-basic"
                label="Vendor Id"
                multiline={true}
                value={vendorIdInput}
                onChange={event => handleSetVendorIdInput(event.target.value)}
                placeholder={"only int values..."}
            />
        </>
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}
            inputFields={inputFields}
        />
    )
}


export default GetBankKey