import React, { useContext } from "react"
import { connect } from "mqtt"

import {
    mqttBrokerUrl,
    mqttProtocol,
    mqttPort,
    mqttPath,
} from '../../APIList'
import { addNewKey } from '../../redux/DisplayMessageCodes/DisplayCodeAction';

import store from "../../redux/store"
import pushMessage from "../../redux/mqttMessages/MessageActions"




// NOTE:    To connect to the broker
const client = connect(mqttBrokerUrl, {
    protocol: mqttProtocol,
    port: mqttPort,
    path: mqttPath,
    username: 'nocAcTRiCARyCLeROmYCErteNati',
    password: 'W&7PhF99e1RiM6phlIheFEp9',
})






/* Redux related things */
client.on("message", (topic, binaryMessage) => {

    console.log('received the message from the topic  ' + topic)
    // console.log('mqtt reducer')


    let { status, message } = messageParser(binaryMessage)

    if (status) {
        console.log('the msg will be displayed')
        try {
            store.dispatch(pushMessage({ topic, message }))
        } catch (error) {
            console.log(error, binaryMessage.toString())
        }
    } else {
        console.log('the msg wont be displayed')
    }
})




const messageParser = binaryMessage => {

    let unformattedMessage = binaryMessage.toString()
    console.log('unformattedMessage')
    console.log(unformattedMessage)

    let obj = ''
    try {
        obj = JSON.parse(unformattedMessage)
    } catch (err) {
        return {
            status: true,
            message: unformattedMessage
        }
    }


    let senderMac = obj['sender_mac_add']
    if (senderMac) {
        senderMac = senderMac.slice(0, 5)
    }
    // console.log(senderMac)


    let receiverMac = ''
    if (obj['receiver_mac']) {
        receiverMac = obj['receiver_mac']
    } else {
        receiverMac = obj['reciever_mac']
    }

    if (receiverMac) {
        receiverMac = receiverMac.slice(0, 5)
    }
    // console.log(receiverMac)

    let messageCode = obj['message_type_code']
    // console.log(messageCode)

    if (!messageCode) {
        return {
            status: true,
            message: unformattedMessage
        }
    }



    const buttonStatus = store.getState()['displayCodeReducer']
    // console.log(buttonStatus)

    // console.log(buttonStatus[messageCode], ' msg code ')
    if (buttonStatus[messageCode] === false) {
        console.log(messageCode)

        return {
            status: false
        }
    } else if (!buttonStatus[messageCode]) {
        store.dispatch(addNewKey({ name: messageCode}))
    }







    let messageBody = obj['message_body']
    // console.log(JSON.stringify(messageBody))
    messageBody = JSON.stringify(messageBody)


    let message = `[${senderMac}] [${receiverMac}] [${messageCode}] : ${messageBody}`

    return {
        status: true,
        message
    }
}








let saveStateToLocalStorageWithDebounce = debounce(
    (storeAsString) => localStorage.setItem("mqttHistory", storeAsString),
    3000
)


// store.subscribe((a) => {
//   saveStateToLocalStorageWithDebounce(JSON.stringify(store.getState()))
// })



function debounce(callback, delay) {
    let timer
    return (stateAsString) => {
        clearTimeout(timer)
        timer = setTimeout(() => callback(stateAsString), delay)
    }
}
/* Redux related things - closing */





// NOTE:    To use the client variable from any component we are using this new react feature.
const mqttClientContext = React.createContext()

export default function createMqttClientContext() {
    return mqttClientContext
}

function useMqttClientContext() {
    return useContext(mqttClientContext)
}


export { useMqttClientContext, client }