
import { VARYING, NON_VARYING } from '../Reusables/constants'

import SendLogcatDetails from '../CommandsComponents/GenericAndroid/SendLogcatDetails'
import StopLogcatDetails from '../CommandsComponents/GenericAndroid/StopLogcatDetails'
import StartTeamViewer from '../CommandsComponents/GenericAndroid/StartTeamViewer'


// NOTE:    All the android devices will also execute these commands
const GenericAndroidCommands = [
    {
        label: "Send Logcat Details",
        component: SendLogcatDetails,
        commandType: VARYING,
    },
    {
        label: "Stop Logcat",
        component: StopLogcatDetails,
        commandType: VARYING,
    },
    {
        label: "Request To Start Teamviewer",
        component: StartTeamViewer,
        commandType: VARYING,
    },
]


export { GenericAndroidCommands }