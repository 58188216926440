
import { VARYING, NON_VARYING } from '../Reusables/constants'
import { GenericAndroidCommands } from './GenericAndroidCommands'
import { GenericCommands } from './GenericCommands'

import ScreenIncrease from '../CommandsComponents/AndroidTv/ScreenIncrease'
import ScreenDecrease from '../CommandsComponents/AndroidTv/ScreenDecrease'
import ChangeTestingUnderMaintenance from '../CommandsComponents/AndroidTv/ChangeTestingUnderMaintenance'
import ChangeNormal from '../CommandsComponents/AndroidTv/ChangeNormal'
import BannerShow from '../CommandsComponents/AndroidTv/BannerShow'
import BannerHide from '../CommandsComponents/AndroidTv/BannerHide'
import DebugShow from '../CommandsComponents/AndroidTv/DebugShow'
import DebugHide from '../CommandsComponents/AndroidTv/DebugHide'


// NOTE:    These are the commands specific to Android-TV
const commands = [
    {
        label: "Screen Increase", 
        component: ScreenIncrease,
        commandType: VARYING,
    },
    {
        label: "Screen Decrease", 
        component: ScreenDecrease,
        commandType: VARYING,
    },
    {
        label: "Change text to testing under maintenance", 
        component: ChangeTestingUnderMaintenance,
        commandType: VARYING,
    },
    {
        label: "Change text to normal", 
        component: ChangeNormal,
        commandType: VARYING,
    },
    {
        label: "Banner Show", 
        component: BannerShow,
        commandType: VARYING,
    },
    {
        label: "Banner Hide", 
        component: BannerHide,
        commandType: VARYING,
    },
    {
        label: "Debug Show", 
        component: DebugShow,
        commandType: VARYING,
    },
    {
        label: "Debug Hide", 
        component: DebugHide,
        commandType: VARYING,
    },
]

// NOTE:    Android-Tv will execute all the commands specific to it, as well as generic-andorid commands
const AndroidTvCommands = GenericCommands.concat(GenericAndroidCommands, commands)


export { AndroidTvCommands }