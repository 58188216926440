
import { VARYING, NON_VARYING } from '../Reusables/constants'
import { GenericCommands } from './GenericCommands'

import CheckIssuerBankKeyAvailability from "../CommandsComponents/Parkbox/CheckIssuerBankKeyAvailability"
import CheckTagStatusOnBasisOfSitepass from '../CommandsComponents/Parkbox/CheckTagStatusOnBasisOfSitePass'
import CustomCommandsInput from "../CommandsComponents/Parkbox/CustomCommandInput"
import RestartParkboxes from "../CommandsComponents/Parkbox/RestartParkboxes"
import SetLogTagAndLevel from "../CommandsComponents/Parkbox/SetLogTagAndLevel"
import SetPowerLevel from "../CommandsComponents/Parkbox/SetPowerLevel"
import SetScanTime from "../CommandsComponents/Parkbox/setScanTime"
import ZeroVariableCommand from "../CommandsComponents/Parkbox/ZeroVariableCommand"

// import ConnectionStatus from "../ConnectionStatus/ConnectionStatus"


// These commands do not take
const commands = [
  {
    /* This needs to be first element other wise things will break */
    label: "Restart All Parkboxes",
    component: RestartParkboxes,
    commandType: VARYING,
  },
  {
    label: "Custom Command",
    component: CustomCommandsInput,
    commandType: VARYING,
  },
  {
    label: "Get Version",
    command: '{"Data_type":"Parkbox","Data":"VERSION"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Restart Parkbox",
    command: '{"Data_type":"Parkbox","Data":"RESTART"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Push OTA",
    command: '{"Data_type":"Parkbox","Data":"OTA"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get RAM",
    command: '{"Data_type":"Parkbox","Data":"RAM"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Show Tasklist",
    command: '{"Data_type":"Parkbox","Data":"TASKLIST"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Task System State",
    command: '{"Data_type":"Parkbox","Data":"TASK_SYSTEM_STATE"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Start Receiving Logs",
    command: '{"Data_type":"Parkbox","Data":"REMOTE_LOG_START"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Stop Receiving Logs",
    command: '{"Data_type":"Parkbox","Data":"REMOTE_LOG_END"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Set Power Level",
    component: SetPowerLevel,
    commandType: VARYING,
  },
  {
    label: "Check Issuer Bank Key Availability",
    component: CheckIssuerBankKeyAvailability,
    commandType: VARYING,
  },
  {
    label: "Set Scan Time",
    component: SetScanTime,
  },
  {
    label: "Set Log Level And Log Tag",
    component: SetLogTagAndLevel,
  },
  {
    label: "Get Current Power",
    command: '{"Data_type":"Parkbox","Data":"GET_CURRENT_POWER"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get Site Info",
    command: '{"Data_type":"Parkbox","Data":"GET_SITE_INFO"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get Client IP",
    command: '{"Data_type":"Parkbox","Data":"GET_CLIENT_IP"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get Current Time",
    command: '{"Data_type":"Parkbox","Data":"GET_CURRENT_TIME"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get Current Temperature",
    command: '{"Data_type":"Parkbox","Data":"GET_CURRENT_TEMPERATURE"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Check Tag Status On Basis Of Sitepass",
    component: CheckTagStatusOnBasisOfSitepass,
    commandType: VARYING,
  }
]

const ParkboxCommands = GenericCommands.concat(commands)


const GET_RAM_COMMAND = { Data_type: "Parkbox", Data: "RAM" }
const GET_VERSION_COMMAND = { Data_type: "Parkbox", Data: "VERSION" }


export { ParkboxCommands, GET_RAM_COMMAND, GET_VERSION_COMMAND }