import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    SET_SCAN_TIME_MESSAGE_CODE,
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"


// NOTE:    To generate the scan time options
const generateScanTimeOptions = () => {
    const scanTimeOptions = []
    for (let i = 3; i <= 255; i++) {
        scanTimeOptions.push(i)
    }
    return scanTimeOptions
}


const SetScanTime = ({ topic }) => {

    const client = useMqttClientContext()

    const scanTimeInputOptions = generateScanTimeOptions()
    const [scanTimeInput, setScanTimeInput] = useState(25)


    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: SET_SCAN_TIME_MESSAGE_CODE,
            message_body: {
                S_TIME: scanTimeInput
            },
        }
    }


    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <Autocomplete
            options={scanTimeInputOptions}
            getOptionLabel={(option) => String(option)}
            style={{ width: 200 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="normal"
                    label="Scan Time"
                    variant="outlined"
                />
            )}
            value={scanTimeInput}
            onChange={(event, value) => {
                setScanTimeInput(value)
            }}
            disableClearable
        />
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}

            inputFields={inputFields}
        />
    )
}


export default SetScanTime