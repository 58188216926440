import React, { useState, useEffect } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    OTA_MESSAGE_CODE
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"



const OTA = ({ topic }) => {

    const client = useMqttClientContext()

    const [hwVersionInput, setHwVersionInput] = useState('')
    const [firmwarePathInput, setFirmwarePathInput] = useState('')


    useEffect(() => {
        const localStorageHwVersion = localStorage.getItem('lastSelectedHwVerionInput')
        if (localStorageHwVersion) {
            setHwVersionInput(localStorageHwVersion)
        }

        const localStorageFirmwarePath = localStorage.getItem('lastSelectedFirmwarePathInput')
        if (localStorageFirmwarePath) {
            setFirmwarePathInput(localStorageFirmwarePath)
        }
    }, [])


    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: OTA_MESSAGE_CODE,
            message_body: {
                "hw_version": hwVersionInput,
                "firmware_path": firmwarePathInput,
            },
        }
    }


    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <div style={{ flexGrow: 1 }}>
            <TextField
                id="standard-basic"
                label="Custom Command"
                multiline={true}
                value={hwVersionInput}
                onChange={(event) => {
                    setHwVersionInput(event.target.value)
                    localStorage.setItem('lastSelectedHwVerionInput', event.target.value)
                }}
                placeholder={"Enter HW Version here..."}
            />
            <TextField
                id="standard-basic"
                label="Custom Command"
                multiline={true}
                value={firmwarePathInput}
                onChange={(event) => {
                    setFirmwarePathInput(event.target.value)
                    localStorage.setItem('lastSelectedFirmwarePathInput', event.target.value)
                }}
                placeholder={"Enter Firmware Path here..."}
            />
        </div>
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}
            inputFields={inputFields}
        />
    )
}


export default OTA