import React, { useEffect, useState } from "react"


import Logger from "./Logger"
import Toolbar from "./Toolbar/Toolbar"
import VerticalTabs from "./TabPanel/Tabs"
import VisibilityButton from "./MessageVisibility/VisibilityButton";

import { AndroidCommands } from './Commands/AndroidCommands'
import { AndroidTvCommands } from './Commands/AndroidTvCommands'
import { JetsonCommands } from './Commands/JetsonCommands'
import { ParkboxCommands } from './Commands/ParkboxCommands'
import { ParkboxNewCommands } from './Commands/ParkboxNewCommands'
import { useMqttClientContext } from "../Contexts/mqttClientContext/mqttClientContext"

import Grid from "@material-ui/core/Grid"


const Panel = (props) => {

	const client = useMqttClientContext()


	// NOTE: 	To store all the devices (TOPICS) installed at a particular gate
	const [gateDevicesOptions, setGateDevicesOptions] = useState([])
	// NOTE:    store the selected device & topic, to render commands on that basis (it will store the topic)
	const [gateDevices, setGateDevices] = useState(null)

	// TODO: 
	// NOTE: 	To store the selected command's index (bcos the commands are rendered through map). So to keep the reference 
	// 				to what command user wants to run, we store the index of it.
	const [selectedIndex, setSelectedIndex] = useState(0)



	/* NOTE: 	This will find the device commands which is selected. Since user only selects the 
				topic, so we need to find the device selected from the selected topic. and return its commands*/
	const findSelectedDeviceCommands = () => {
		let topicNameSplitted = gateDevices['name'].split('_')
		let selectedDeviceCommands;

		if (topicNameSplitted[1] === 'android' && topicNameSplitted[2] === 'tv') {
			selectedDeviceCommands = AndroidTvCommands
		} else if (topicNameSplitted[1] === 'android') {
			selectedDeviceCommands = AndroidCommands
		} else if (topicNameSplitted[1] === 'jetson') {
			selectedDeviceCommands = JetsonCommands
		} else if (topicNameSplitted[1] === 'parkbox') {
			selectedDeviceCommands = ParkboxNewCommands
		} else {
			selectedDeviceCommands = ParkboxCommands
		}

		return selectedDeviceCommands
	}

	// NOTE: 	To find the device selected from the topic name. 
	const findSelectedDevice = () => {
		let topicNameSplitted = gateDevices['name'].split('_')

		if (topicNameSplitted[1] === 'android' && topicNameSplitted[2] === 'tv') {
			return 'android_tv'
		} else if (topicNameSplitted[1] === 'android') {
			return 'android'
		} else if (topicNameSplitted[1] === 'jetson') {
			return 'jetson'
		} else {
			return 'parkbox'
		}
	}


	return (
		<>
			{/* 
				NOTE: 	the toolbar component renders the top bar of the panel, which allows user to select,
						site, gate, device/topic, and also shows status etc.
			*/}
			<Toolbar
				gateDevicesOptions={gateDevicesOptions}
				setGateDevicesOptions={setGateDevicesOptions}
				gateDevices={gateDevices}
				setGateDevices={setGateDevices}

				setSelectedIndex={setSelectedIndex}
			/>

			{/* 	NOTE:	this will render all the commands of the selected device		*/}
			<Grid container spacing={0}>
				<Grid item xs={5}>
					<div
						style={{
							padding: 24,
							backgroundColor: "#f5f5f5",
							borderRadius: 4,
							margin: 5,
						}}
					>
						{gateDevices && <VerticalTabs
							selectedDeviceCommands={findSelectedDeviceCommands()}
							topic={gateDevices['name']}

							selectedIndex={selectedIndex}
							setSelectedIndex={setSelectedIndex}
						/>
						}
					</div>
				</Grid>


				{/* NOTE: 	To render the recieved message from the topic owner. */}
				<Grid item xs={7}>
					{gateDevices ? <Logger
						gateDevices={gateDevices}
						selectedDevice={findSelectedDevice()}
						topic={gateDevices['name']}

						selectedIndex={selectedIndex}
					/> : null
					}
				</Grid>
			</Grid>

			<VisibilityButton />
		</>
	)
}

export default Panel

