import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    SEARCH_SITE_PASS_MESSAGE_CODE
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"



const SearchSitePass = ({ topic }) => {

    const client = useMqttClientContext()

    const [epcIdInput, setEpcIdInput] = useState('')


    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: SEARCH_SITE_PASS_MESSAGE_CODE,
            message_body: {
                EPC_ID: epcIdInput
            },
        }
    }


    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <div style={{ flexGrow: 1 }}>
            <TextField
                id="standard-basic"
                label="Custom Command"
                multiline={true}
                value={epcIdInput}
                onChange={(event) => setEpcIdInput(event.target.value)}
                placeholder={"Enter epc-id here..."}
            />
        </div>
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}
            inputFields={inputFields}
        />
    )
}


export default SearchSitePass