import axios from "axios"
import { toast } from "react-toastify"

import baseUrl from "../APIList"

const authAxios = axios.create({
    baseURL: baseUrl,
})


// NOTE:    If you don't know about interceptors refer to the below blog to understand it.
//              https://blog.bitsrc.io/setting-up-axios-interceptors-for-all-http-calls-in-an-application-71bc2c636e4e


// NOTE:    This function always runs before the request is being sent to the client.
//          It sets the required authorization header to the request, so it is not rejected.
authAxios.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        let localStorageToken = localStorage.getItem("mqttDashboardToken")
        let sessionStorageToken = sessionStorage.getItem("mqttDashboardToken")

        let token = localStorageToken || sessionStorageToken
        config.headers.Authorization = `Token ${token}`
        // config.headers.Authorization = `Token 4c673d2e6f0ec78da1dc02a43ecf4fc9be37aa82`

        return config
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error)
    }
)

// NOTE:    This function always runs when response comes.
authAxios.interceptors.response.use(
    // NOTE:    It comes inside this (first function) if the response code is 200
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    },
    function (error) {
        toast.error("error occured in fetching data")
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)


export default authAxios