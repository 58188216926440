
// NOTE:    Since the all below part was repeating in 'CommandComponents" (folder) of Android TV,
//          Generic, Generic Android, Jetson. So, we have made a reusable component for that


import React from 'react'
import JSONPretty from 'react-json-pretty'

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"


// NOTE:    CSS 
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: 500,
        display: "flex",
        flexDirection: "column",
        padding: 24,
        boxSizing: "border-box",
        overflow: "hidden",
    },
}))


const RenderJsonAndButton = ({ topic, jsonCommand, handleSend, inputFields }) => {

    let classes = useStyles()

    return (
        <Box className={classes.root}>

            <pre> {topic} </pre>

            {/* NOTE:   To display the json command which 'll sent on button press.
                        So the user 'll know that what json is being sent for particular command */}
            <div>
                <JSONPretty
                    id="json-pretty"
                    data={JSON.stringify(jsonCommand)}
                ></JSONPretty>
            </div>

            {/* NOTE:   To render the input fields if the component requires user input */}
            { inputFields && inputFields() }

            {/* NOTE:    Send button to publish the command to the specific topic */}
            <div style={{ textAlign: "right", padding: 20 }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSend()}
                >
                    send
                </Button>
            </div>

        </Box>
    )
}


export default RenderJsonAndButton