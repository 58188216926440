import React, { useState } from "react"
import JSONPretty from "react-json-pretty"

import { useMqttClientContext } from "../../../Contexts/mqttClientContext/mqttClientContext"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: 500,
		display: "flex",
		flexDirection: "column",
		padding: 24,
		boxSizing: "border-box",
		overflow: "hidden",
	},
}))


function generateCommandJson(sitepassId) {
	return { Data_type: "Parkbox", Data: "SITEPASS", Sitepass_Id: sitepassId }
}

/* 
This component is to providing input interface to user to type in custom commands. 
*/
const CheckTagStatusOnBasisOfSitepass = ({ topic }) => {

	let classes = useStyles()
	const client = useMqttClientContext()

	const [sitepassId, setSitePassId] = useState("")

	const handleSend = (command) => {
		let commandJSON = JSON.stringify(generateCommandJson(sitepassId))
		client.publish(topic, commandJSON, {}, function (err) {
			if (err) {
				alert(err)
			}
		})
	}


	return (
		<Box className={classes.root}>
			<pre>{topic}</pre>
			<div>
				{/* json.parse, exception needs to be handled */}
				<JSONPretty
					id="json-pretty"
					data={JSON.stringify(generateCommandJson(sitepassId))}
				></JSONPretty>
			</div>

			<div style={{ flexGrow: 1 }}>
				{/* here goes input field */}
				<TextField
					id="standard-basic"
					label="Sitepass Id"
					multiline={true}
					value={sitepassId}
					onChange={(event) => setSitePassId(event.target.value)}
					placeholder={"Enter Sitepass here..."}
				/>
			</div>

			<div style={{ textAlign: "right", padding: 20 }}>
				<Button
					color="primary"
					variant="contained"
					onClick={() => handleSend(sitepassId)}
				>
					send
				</Button>
			</div>
		</Box>
	)
}


export default CheckTagStatusOnBasisOfSitepass