
import { VARYING, NON_VARYING } from '../Reusables/constants'
import { GenericAndroidCommands } from './GenericAndroidCommands'
import { GenericCommands } from './GenericCommands'

import ClearCache from '../CommandsComponents/Android/ClearCache'



// NOTE:    These are the commands specific to Android-TV
const commands = [
    {
        label: "Clear Cache",
        component: ClearCache,
        commandType: VARYING,
    }
]


// NOTE:    Android-Tv will execute all the commands specific to it, as well as generic-andorid commands
const AndroidCommands = GenericCommands.concat(GenericAndroidCommands, commands)


export { AndroidCommands }