import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"

import { useMqttClientContext } from "../Contexts/mqttClientContext/mqttClientContext"
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    GET_PARKBOX_VERSION_MESSAGE_CODE,
    IS_ALIVE_MESSAGE_CODE,
    generateCurrentEpocTimeInSeconds
} from './Reusables/constants'


// NOTE: TO LET US KNOW IF USER IS AT THE BOTTOM OR NOT
// 			SO WE CAN RUN WHETHER WE NEED TO SCROLL TO 
// 			THE BOTOOM IF THE NEW MESSAGE IS ADDED
// let isScrolled = false



// NOTE: TO FORMAT MSG BASED ON COLOURS 
const formatMessage = (message) => {

    // console.log('message')
    // console.log(message)

    // if (message.includes('9002')) {
    //   console.log('9002')
    // }

    if (message.includes('9002')) {

        if (message.includes('I')) {
            return (<>
                <span style={{ color: 'green' }}>
                    {message}
                </span>
            </>)
        } else if (message.includes('W')) {
            return (<>
                <span style={{ color: 'yellow' }}>
                    {message}
                </span>
            </>)
        } else if (message.includes('E')) {
            console.log('inside error')
            return (<>
                <span style={{ color: 'red' }}>
                    {message}
                </span>
            </>)
        } else if (message.includes('D')) {
            return (
                <>
                    <span style={{ color: 'gray' }}>
                        {message}
                    </span>
                </>
            )
        }
    }

    // console.log('before message return ')
    return message
}


const Logger = (props) => {

    const client = useMqttClientContext()
    // const ref = useRef(null)

    const [messages, setMessages] = useState([])


    // NOTE: TO SCROLL TO BOTTOM WHEN THE NEW MESSAGE IS UPDATED(IF USER IS ALREADY AT THE BOTTOM)
    // useEffect(() => {
    // 	const messagesElement = document.getElementById('panel-logger')

    // 	console.log('is scrolled', isScrolled)

    // 	if (isScrolled) {
    // 		messagesElement.scrollTop = messagesElement.scrollHeight
    // 	}
    // }, [props.topicMessages])


    // NOTE: 	Whenever the topic is selected, this logger component will be rendered and we will subscribe to the 
    // 			selected topic and will do some initial work.
    useEffect(() => {
        client.subscribe(props.topic, err => {
            if (!err) {
                console.log('We have subscribed to the topic ' + props.topic)

                sendInitialCommands(props.topic)

            }
        })

        return () => {
            client.unsubscribe(props.topic)
        }
    }, [props.gateDevices])



    const generateIsAliveJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: IS_ALIVE_MESSAGE_CODE,
            message_body: "",
            message_time: generateCurrentEpocTimeInSeconds(),
        }
    }

    const generateGetParkboxVersionJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: GET_PARKBOX_VERSION_MESSAGE_CODE,
            message_body: "",
        }
    }


    const sendInitialCommands = (topic) => {

        client.publish(topic, JSON.stringify(generateIsAliveJsonCommand()), {}, err => {
            if (err) {
                alert(err)
            }    
        })

        if (props.topic.includes('parkbox')) {
            console.log('inside parkbox')
            
            client.publish(topic, JSON.stringify(generateGetParkboxVersionJsonCommand()), {}, err => {
                if (err) {
                    alert(err)
                }
            })
        }

    }




    return (
        <div
            id="panel-logger"
            style={{
                backgroundColor: "#272c34",
                height: 500,
                borderRadius: 4,
                margin: 5,
                padding: 24,
                overflowY: "auto",
            }}
        // ref={ref}
        >
            {
                props.topicMessages?.map((message, index) => {
                    return (
                        <div
                            style={{ textAlign: "left", overflowWrap: "break-word" }}
                            key={message.key}
                        >
                            <b style={{ color: "green" }}>→</b>
                            <div
                                style={{
                                    color: "white",
                                    display: "inline",
                                    overflowWrap: "break-word",
                                    fontFamily: "monospace",
                                }}
                            >
                                {formatMessage(message.message)}
                                {/* { props.topicMessages[props.topicMessages.length - 1 - index].message } */}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}



const mapStateToProps = (state, ownProps) => {

    let topicMessages = state.messageReducer[ownProps.topic]

    // NOTE: 	TO CHECK IF USER IS AT THE BOTOOM OR NOT
    // 			SO WE CAN PERFORM SCROLLING OPTION ON THAT BASIS
    // const messagesElement = document.getElementById('panel-logger')
    // if (messagesElement) {
    // 	const shouldScroll = messagesElement.scrollHeight - Math.abs(messagesElement.scrollTop) === messagesElement.clientHeight
    // 	isScrolled = shouldScroll
    // }

    let messagesClone
    if (Array.isArray(topicMessages)) {
        messagesClone = [...topicMessages]
        messagesClone.reverse()
    }
    else {
        messagesClone = []
    }


    return { topicMessages: messagesClone }
}


export default connect(mapStateToProps)(Logger)


