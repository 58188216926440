import React, { useState } from "react"
import JSONPretty from "react-json-pretty"

import { useMqttClientContext } from "../../../Contexts/mqttClientContext/mqttClientContext"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: 500,
        display: "flex",
        flexDirection: "column",
        padding: 24,
        boxSizing: "border-box",
        overflow: "hidden",
    },
}))


function generateCommandJson(command) {
    return command
}

/* 

This component is to providing input interface to user to type in custom commands. 

*/
const CustomCommandsInput = ({ topic }) => {
    let classes = useStyles()
    const client = useMqttClientContext()
    const [command, setCommand] = useState(null)

    const handleSend = (command) => {
        let commandJSON = generateCommandJson(command)
        client.publish(topic, commandJSON, {}, function (err) {
            if (err) {
                alert(err)
            }
        })
    }

    return (
        <Box className={classes.root}>
            <pre>{topic}</pre>
            
            <div>
                {/* json.parse, exception needs to be handled */}
                <JSONPretty
                    id="json-pretty"
                    data={generateCommandJson(command)}
                ></JSONPretty>
            </div>

            <div style={{ flexGrow: 1 }}>
                {/* here goes input field */}
                <TextField
                    id="standard-basic"
                    label="Custom Command"
                    multiline={true}
                    value={command}
                    onChange={(event) => setCommand(event.target.value)}
                    placeholder={"Enter command here..."}
                />
            </div>

            <div style={{ textAlign: "right", padding: 20 }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleSend(command)}
                >
                    send
                </Button>
            </div>
        </Box>
    )
}

export default CustomCommandsInput
