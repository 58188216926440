import React, { useState, useEffect, useReducer } from "react"
import JSONPretty from "react-json-pretty"

import authAxios from "../../../axiosConfigured/axiosConfigured"
import { useMqttClientContext } from "../../../Contexts/mqttClientContext/mqttClientContext"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete/"
import { makeStyles } from "@material-ui/core/styles"

/* 
These commands do have any parameters, they have data to be sent to parkbox 
that can vary. 

{"Data_type":"Parkbox","Data":"BANK_KEY", "ISSUER_KEY":607417, "VENDOR_ID":13}

Here ISSUER_KEY and VENDOR_ID are variable parameters and user may change this. 
*/

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: 500,
        display: "flex",
        flexDirection: "column",
        padding: 24,
        boxSizing: "border-box",
        overflow: "hidden",
    },
}))

/* 

Purpose of this function is to validate if power level is between 0 and 30 or not.
this function return error text if above condition is not satisfied
this function doesnot return error text if the supplied value is empty

*/

const generateCommandJson = (issuerKey, vendorId) => ({
    Data_type: "Parkbox",
    Data: "BANK_KEY",
    ISSUER_KEY: issuerKey || "",
    VENDOR_ID: (vendorId && vendorId.supplier_npci_id) || "",
})


const CheckIssuerBankKeyAvailability = ({ topic }) => {
    let classes = useStyles()
    const client = useMqttClientContext()
    const [keyDetails, setKeyDetails] = useState([])
    const [vendorIdOptions, setVendorIdOptions] = useState([])
    const [issuerKey, setIssuerKey] = useState(null)
    const [vendorId, setVendorId] = useState("")

    const handleSend = (issuerKey, VendorId) => {
        client.publish(
            topic,
            JSON.stringify(generateCommandJson(issuerKey, VendorId)),
            {},
            function (err) {
                if (err) {
                    alert(err)
                }
            }
        )
    }

    useEffect(() => {
        authAxios
            .get("parkzap/epcid/banker/bank_details/")
            .then((res) => {
                setKeyDetails(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        // IssuerKey can either be null or an object
        //
        setVendorIdOptions([])
        setVendorId(null)
        if (issuerKey) {
            authAxios
                .post("parkzap/epcid/banker/get_tag_supplier/", {
                    bank_iin: issuerKey.issuer_bank_id,
                })
                .then((res) => {
                    if (res.data) {
                        setVendorIdOptions(res.data.tag_supplier_list)
                    }
                })
        }
    }, [issuerKey])

    return (
        <Box className={classes.root}>
            <pre>{topic}</pre>
            <div>
                {/* json.parse, exception needs to be handled */}
                <JSONPretty
                    id="json-pretty"
                    data={generateCommandJson(
                        issuerKey && issuerKey.issuer_bank_id,
                        vendorId
                    )}
                ></JSONPretty>
            </div>
            <div style={{ flexGrow: 1 }}>
                <Autocomplete
                    options={keyDetails}
                    getOptionLabel={(option) => String(option.issuer_bank_name)}
                    style={{ width: 200 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="normal"
                            label="Issuer Key"
                            variant="outlined"
                        />
                    )}
                    value={issuerKey}
                    onChange={(event, value) => {
                        setIssuerKey(value)
                    }}
                // disableClearable
                />
            </div>
            <div style={{ flexGrow: 1 }}>
                <Autocomplete
                    options={vendorIdOptions}
                    getOptionLabel={(option) => String(option.supplier_npci_id)}
                    style={{ width: 200 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="normal"
                            label="Vendor Id"
                            variant="outlined"
                        />
                    )}
                    value={vendorId}
                    onChange={(event, value) => {
                        setVendorId(value)
                    }}
                // disableClearable
                />
            </div>
            <div style={{ textAlign: "right", padding: 20 }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                        handleSend(issuerKey && issuerKey.issuer_bank_id, vendorId)
                    }
                    disabled={!(issuerKey && vendorId)}
                >
                    send
        </Button>
            </div>
        </Box>
    )
}

export default CheckIssuerBankKeyAvailability
