import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    SEND_LOGCAT_DETAILS_MESSAGE_CODE,
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import Autocomplete from "@material-ui/lab/Autocomplete/"
import TextField from "@material-ui/core/TextField"


// NOTE:    Json sent will have a filter type which will be selected by user from 
//              the below options
const filterTypes = [
    { name: "Verbose", initial: 'V' },
    { name: "Debug", initial: 'D' },
    { name: "Info", initial: 'I' },
    { name: "Warning", initial: 'W' },
    { name: "Error", initial: 'E' },
]


const SendLogcatDetails = ({ topic }) => {

    // NOTE:    Filter type selected by user will be stored in this variable.
    const [selectedFilterType, setSelectedFilterType] = useState(filterTypes[0])

    const client = useMqttClientContext()

    
    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: SEND_LOGCAT_DETAILS_MESSAGE_CODE,
            message_body: { filter_type: selectedFilterType ? selectedFilterType.initial : '', filter_tag: "" },
        }
    }

    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (

        <div style={{ flexGrow: 1 }}>
            <Autocomplete
                options={filterTypes}
                getOptionLabel={(option) => String(option.name)}
                style={{ width: 200 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="normal"
                        label="Filter types"
                        variant="outlined"
                    />
                )}
                value={selectedFilterType}
                onChange={(event, value) => {
                    setSelectedFilterType(value)
                }}
            />
        </div>
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}
            inputFields={inputFields}
        />
    )
}


export default SendLogcatDetails