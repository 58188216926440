import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    SET_BAUD_RATE_MESSAGE_CODE
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"



const SetBaudRate = ({ topic }) => {

    const client = useMqttClientContext()

    const [baudRateInputOptions, setBaudRateInputOptions] = useState([
        9600,
        19200,
        38400,
        57600,
        115200,
    ])
    const [baudRateInput, setBaudRateInput] = useState(9600)


    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: SET_BAUD_RATE_MESSAGE_CODE,
            message_body: {
                "baud_rate": baudRateInput
            },
        }
    }

    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <>
            <Autocomplete
                options={baudRateInputOptions}
                getOptionLabel={option => option}
                style={{ width: 200 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="normal"
                        label="Baud Rate"
                        variant="outlined"
                    />
                )}
                value={baudRateInput}
                onChange={(event, value) => {
                    setBaudRateInput(value)
                }}
                disableClearable
            />
        </>
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}

            inputFields={inputFields}
        />
    )
}


export default SetBaudRate