import React from 'react';
import { connect } from 'react-redux'

import { updateCheckboxes } from '../../redux/DisplayMessageCodes/DisplayCodeAction'
// import {
//     GET_NETWORK_DETAILS_REPLY_CODE,
//     GET_FASTAG_STATUS_MESSAGE_CODE,
//     GET_FASTAG_STATUS_REPLY_CODE,
//     FASTAG_DETAILS_MESSAGE_CODE,
//     FASTAG_DETAILS_REPLY_CODE,
// } from '../Reusables/constants'


import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);



const VisibilityButton = (props) => {

    const handleChange = (event) => {
        // setState({ ...state, [event.target.name]: event.target.checked });
        // console.log('inisde handle change')
        // console.log(event.target)

        props.updateCheckboxes(event.target.name, event.target.checked)
    };

    // console.log('state.displayCodeReducer', state.messageReducer.blinkValue)
    // console.log('props', props)


    return (
        <>

            {/* <FormControlLabel
                control={<GreenCheckbox checked={props[GET_NETWORK_DETAILS_REPLY_CODE]} onChange={handleChange} name={GET_NETWORK_DETAILS_REPLY_CODE} />}
                label={GET_NETWORK_DETAILS_REPLY_CODE}
            />

            <FormControlLabel
                control={<GreenCheckbox checked={props[GET_FASTAG_STATUS_MESSAGE_CODE]} onChange={handleChange} name={GET_FASTAG_STATUS_MESSAGE_CODE} />}
                label={GET_FASTAG_STATUS_MESSAGE_CODE}
            />

            <FormControlLabel
                control={<GreenCheckbox checked={props[GET_FASTAG_STATUS_REPLY_CODE]} onChange={handleChange} name={GET_FASTAG_STATUS_REPLY_CODE} />}
                label={GET_FASTAG_STATUS_REPLY_CODE}
            />

            <FormControlLabel
                control={<GreenCheckbox checked={props[FASTAG_DETAILS_MESSAGE_CODE]} onChange={handleChange} name={FASTAG_DETAILS_MESSAGE_CODE} />}
                label={FASTAG_DETAILS_MESSAGE_CODE}
            />

            <FormControlLabel
                control={<GreenCheckbox checked={props[FASTAG_DETAILS_REPLY_CODE]} onChange={handleChange} name={FASTAG_DETAILS_REPLY_CODE} />}
                label={FASTAG_DETAILS_REPLY_CODE}
            /> */}

            {/* {
                Object.keys(props.messageCode).map(key => <p>{key}</p>)
            } */}

            {
                Object.keys(props.messageCode).map((key, index) => (
                    <FormControlLabel
                        control={<GreenCheckbox checked={props.messageCode[key]} onChange={handleChange} name={key} />}
                        label={key} key={index}
                    />
                ))
            }

        </>
    );
}


const mapStateToProps = (state, ownProps) => {
    // console.log('mapprops', state.displayCodeReducer[GET_FASTAG_STATUS_REPLY_CODE])

    return {
        messageCode: state.displayCodeReducer,
        // [GET_NETWORK_DETAILS_REPLY_CODE]: state.displayCodeReducer[GET_NETWORK_DETAILS_REPLY_CODE],
        // [GET_FASTAG_STATUS_MESSAGE_CODE]: state.displayCodeReducer[GET_FASTAG_STATUS_MESSAGE_CODE],
        // [GET_FASTAG_STATUS_REPLY_CODE]: state.displayCodeReducer[GET_FASTAG_STATUS_REPLY_CODE],
        // [FASTAG_DETAILS_MESSAGE_CODE]: state.displayCodeReducer[FASTAG_DETAILS_MESSAGE_CODE],
        // [FASTAG_DETAILS_REPLY_CODE]: state.displayCodeReducer[FASTAG_DETAILS_REPLY_CODE],
    }
}


const mapDispatchToProps = dispatch => {
    return {
        updateCheckboxes: (name, checked) => dispatch(updateCheckboxes({ name, checked }))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VisibilityButton)