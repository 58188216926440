// let map = new WeakMap()
let index = 0

// NOTE: BCOS WE GOT PROBLEM WHILE RENDERING THE MESSAGES (SLOWNESS)
function weakKey(obj) {
  //   let key = map.get(obj)
  //   if (!key) {
  let key
  key = "weak-key" + index++
  // map.set(obj, key)
  //   }
  return key
}

// this function can return three value.
// when a message arrives and it is not a ping message then value will be 0
// when a message arrives and it is a ping message then based on the parameter it retrurs two values
// if 0 -> 0
// if 1 -> 2
// if 2 -> 1
function getBlinkValue(prevValue) {
  switch (prevValue) {
    case 0:
      return 1
    case 1:
      return 2
    case 2:
      return 1
    default:
      return 0
  }
}

export { weakKey, getBlinkValue }
