
import { VARYING, NON_VARYING } from '../Reusables/constants'
import { GenericCommands } from './GenericCommands'

import GetCurrentOCR from '../CommandsComponents/Jetson/GetCurrentOCR'



// NOTE:    These are the commands specific to Jetson
const commands = [
    {
        label: "Get Current OCR",
        component: GetCurrentOCR,
        commandType: VARYING
    },
]

// NOTE:    Jetson will execute all the commands specific to it, as well as generic commands
const JetsonCommands = GenericCommands.concat(commands)


export { JetsonCommands }