let baseUrl
let httpsprefix = "https://"
let httpprefix = "http://"

// =============================================================================================
// NOTE:    Environment configuration
// =============================================================================================
// const productionMqttBrokerUrl = "mqtt://mqtt-parbox.parkzap.com"
const productionMqttBrokerUrl = "wss://mq.parkzap.com"
const productionMqttProtocol = "wss"
const productionMqttPort = 443
const productionMqttPath = "/mqtt"

const testingMqttBrokerUrl = "wss://mq.parkzap.com"
const testingMqttProtocol = "wss"
const testingMqttPort = 443
const testingMqttPath = "/mqtt"

export let mqttBrokerUrl = ""
export let mqttProtocol = ""
export let mqttPort = ""
export let mqttPath = ""
// ==============================================================================================


// NOTE:    Backend host URL
const testingHost = "testing.parkzap.com"
const productionHost = "live23.parkzap.com"

// NOTE:    Mqtt client's possible url (of dashboard)
const productionMqttHost = "dashboard.parkzap.com"
const testMqttHost = "test-mqtt.parkzap.com"
const localhost = "localhost"

// NOTE:    To get the URI of the current environment
let host = window.location.hostname
host = host.toLowerCase()


// NOTE:    Function to set baseURL and mqtt's connect variables based on the environment 
const setEnvironmentVariables = env => {
    if (env === 'testing') {
        baseUrl = httpsprefix + testingHost

        mqttBrokerUrl = testingMqttBrokerUrl
        mqttProtocol = testingMqttProtocol
        mqttPort = testingMqttPort
        mqttPath = testingMqttPath
    } else if (env === 'production') {
        baseUrl = httpsprefix + productionHost

        mqttBrokerUrl = productionMqttBrokerUrl
        mqttProtocol = productionMqttProtocol
        mqttPort = productionMqttPort
        mqttPath = productionMqttPath
    }
}


// NOTE:    Set the environemnt variables based on the environment
switch (host) {
    case productionMqttHost:
        setEnvironmentVariables('production')
        break
    case testMqttHost:
        setEnvironmentVariables('testing')
        break
    case localhost:
        setEnvironmentVariables('production')
        // setEnvironmentVariables('production')
        break
    default:
        baseUrl = httpprefix + testingHost
        break
}


export default baseUrl
