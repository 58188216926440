import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    SET_RFID_BAND_MESSAGE_CODE
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"



const SetRfidBand = ({ topic }) => {

    const client = useMqttClientContext()

    const [bandInputOptions, setbandInputOptions] = useState([
        "CHINESE",
        "USER",
        "US",
        "KOREAN",
    ])
    const [bandInput, setBandInput] = useState(bandInputOptions[0])
    const [nMaxInput, setNMaxInput] = useState('')
    const [nMinInput, setNMinInput] = useState('')

    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: SET_RFID_BAND_MESSAGE_CODE,
            message_body: {
                "BAND": bandInput,
                "NMAX": nMaxInput,
                "NMIN": nMinInput,
            },
        }
    }

    // NOTE:    The below two functions are for validation purposes.(to allow only integer values)
    const handleNMaxInput = value => {
        const regex = /^[0-9]+$/g;

        if (value === '' || regex.test(value)) {
            setNMaxInput(value)
        }
    }

    const handleNMinInput = value => {
        const regex = /^[0-9]+$/g;

        if (value === '' || regex.test(value)) {
            setNMinInput(value)
        }
    }


    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <>
            <Autocomplete
                options={bandInputOptions}
                getOptionLabel={option => option}
                style={{ width: 200 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="normal"
                        label="Band"
                        variant="outlined"
                    />
                )}
                value={bandInput}
                onChange={(event, value) => {
                    setBandInput(value)
                }}
                disableClearable
            />


            <TextField
                id="standard-basic"
                label="N-Max"
                multiline={true}
                value={nMaxInput}
                onChange={event => handleNMaxInput(event.target.value)}
                placeholder={"only int values..."}
            />

            <TextField
                id="standard-basic"
                label="N-Min"
                multiline={true}
                value={nMinInput}
                onChange={event => handleNMinInput(event.target.value)}
                placeholder={"only int values..."}
            />
        </>
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}

            inputFields={inputFields}
        />
    )
}


export default SetRfidBand