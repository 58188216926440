import React from "react"

import { ToastContainer } from "react-toastify"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom"

import "./App.css"
import "react-toastify/dist/ReactToastify.css"
import AppRenderer from "./AppRenderer"
import SignIn from "./components/Login/SignIn"
import logo from "./logo.svg"




function AuthRoute({ component: Component, ...rest }) {
    let auth = false
    
    let localStorageToken = localStorage.getItem("mqttDashboardToken")
    let sessionStorageToken = sessionStorage.getItem("mqttDashboardToken")
    if (localStorageToken || sessionStorageToken) {
        auth = true
    }


    // NOTE: TEMPORARY SETTING AUTH VALUE TO TRUE, TO WORK ON THE PROJECT IN LOCAL
    // TODO:    DON'T FORGET TO REMOVE BEFORE DEPLOYMENT
    // auth = true
    

    return (
        <Route
            render={(props) =>
                auth ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    )
}


function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    {/* NOTE:   THIS 'll check whether the user is logged in or not. If not it ll redirect to login page */}
                    <AuthRoute path="/" exact component={AppRenderer} />

                    <Route path="/login" render={(props) => <SignIn />} />

                    {/* TODO:   Please make it working and proper direction if any wrong path is mentioned */}
                    <Route path='/' render={ props => <h1> wrong url </h1> } />

                </Switch>
            </Router>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}


export default App