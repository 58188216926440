import { createStore } from "redux"

import mqttReducer from "./reducer"

const store = createStore(
  mqttReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
