import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    SET_RFID_BAND_MESSAGE_CODE
} from '../../Reusables/constants'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"


// NOTE:
const generateRfidPowerOptions = () => {
    const rfidPowerOptions = []
    for (let i = 0; i <= 30; i++) {
        rfidPowerOptions.push(i)
    }
    return rfidPowerOptions
}


const SetRfidPower = ({ topic }) => {

    const client = useMqttClientContext()

    const rfidPowerInputOptions = generateRfidPowerOptions()
    const [rfidPowerInput, setRfidPowerInput] = useState(25)


    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: SET_RFID_BAND_MESSAGE_CODE,
            message_body: {
                S_POWER: rfidPowerInput
            },
        }
    }


    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <Autocomplete
            options={rfidPowerInputOptions}
            getOptionLabel={(option) => String(option)}
            style={{ width: 200 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="normal"
                    label="Scan Time"
                    variant="outlined"
                />
            )}
            value={rfidPowerInput}
            onChange={(event, value) => {
                setRfidPowerInput(value)
            }}
            disableClearable
        />
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}

            inputFields={inputFields}
        />
    )
}


export default SetRfidPower