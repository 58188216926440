
import { VARYING, NON_VARYING } from '../Reusables/constants'

import CustomCommand from '../CommandsComponents/Generic/CustomCommand'
import IsAlive from '../CommandsComponents/Generic/IsAlive'
import GetNetworkDetails from '../CommandsComponents/Generic/GetNetworkDetails'
import GetLocalMqttServer from '../CommandsComponents/Generic/GetLocalMqttServer'
import ResetDevice from '../CommandsComponents/Generic/ResetDevice'
import StartCopyLocalMessagesToRemoteBroker from '../CommandsComponents/Generic/StartCopyLocalMessagesToRemoteBroker'
import StopCopyLocalMessagesToRemoteBroker from '../CommandsComponents/Generic/StopCopyLocalMessagesToRemoteBroker'
import TopicSubscriberDisconnecting from '../CommandsComponents/Generic/TopicSubscriberDisconnecting'




// NOTE:    all the devices will also execute these commands
const GenericCommands = [
    {
        label: "Is Alive",
        component: IsAlive,
        commandType: VARYING,
    },
    {
        label: "Custom Command",
        component: CustomCommand,
        commandType: VARYING,
    },
    {
        label: "Get Network Details",
        component: GetNetworkDetails,
        commandType: VARYING,
    },
    {
        label: "Restart Device",
        component: ResetDevice,
        commandType: VARYING,
    },
    {
        label: "Get Local MQTT Server",
        component: GetLocalMqttServer,
        commandType: VARYING,
    },
    {
        label: "Topic Subscriber Disconnecting",
        component: TopicSubscriberDisconnecting,
        commandType: VARYING,
    },
    {
        label: "Start Copy Local Messages to Remote Broker",
        component: StartCopyLocalMessagesToRemoteBroker,
        commandType: VARYING,
    },
    {
        label: "Stop Copy Local Messages to Remote Broker",
        component: StopCopyLocalMessagesToRemoteBroker,
        commandType: VARYING,
    },
]


export { GenericCommands }