import React from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'
import {
    SENDER_MAC_ADDRESS,
    RECEIVER_MAC_ADDRESS,
    BANNER_SHOW_MESSAGE_CODE,
} from '../../Reusables/constants'
import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'



const BannerShow = ({ topic }) => {

    const client = useMqttClientContext()


    const generateJsonCommand = () => {
        return {
            sender_mac_add: SENDER_MAC_ADDRESS,
            receiver_mac: RECEIVER_MAC_ADDRESS,
            message_type_code: BANNER_SHOW_MESSAGE_CODE,
            message_body: {
                "type": "banner_show"
            },
        }
    }

    const handleSend = () => {
        let JSONCommand = JSON.stringify(generateJsonCommand())

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}
        />
    )
}


export default BannerShow