// import axios from "axios"
import { connect } from "react-redux"
import { GiBroom } from "react-icons/gi"
import React, { useState, useEffect } from "react"

import { clearHistory } from "../../redux/mqttMessages/MessageActions.js"
import { GET_SITE_LIST_PATH, GET_GATE_LIST_PATH, GET_TOPIC_LIST_PATH } from '../Reusables/constants'

import authAxios from "../../axiosConfigured/axiosConfigured"
import ConnectionStatus from "./ConnectionStatus"
import EnableDisablePing from "./EnableDisablePing"
import UserAvtar from "./UserAvatar"

import Select from "@material-ui/core/Select"
import Tooltip from "@material-ui/core/Tooltip"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { makeStyles } from "@material-ui/core/styles"
import FormHelperText from "@material-ui/core/FormHelperText"


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))


const Toolbar = ({
    gateDevicesOptions,
    setGateDevicesOptions,
    gateDevices,
    setGateDevices,

    setSelectedIndex,

    ...props
}) => {

    const classes = useStyles()

    // NOTE:    To store all the sites
    const [siteOptions, setSiteOptions] = useState([])
    // NOTE:    To store the site selected by the user from siteOptions
    const [site, setSite] = useState(null)
    // NOTE:    To store all the gates available at particular site
    const [gateOptions, setGateOptions] = useState([])
    // NOTE:    To store the gate selected by the user from gateOptions
    const [gate, setGate] = useState(null)


    const handleChange = (event) => {
        setSite(event.target.value)
    }


    useEffect(() => {
        authAxios
            .get( GET_SITE_LIST_PATH)

            // TODO: FOR TESTING IN LOCAL
            // .get('https://run.mocky.io/v3/9de7bbd5-c1f2-4365-bec3-49fce42b1461')

            .then( res => {
                const data = res.data
                setSiteOptions(data)
                
                let lastSelectedSite = localStorage.getItem('lastSelectedSite')
                if (lastSelectedSite) {
                    setSite(data.filter(option => option.name === lastSelectedSite)[0])
                }
            })
            .catch( err => {
                console.log(err.response)
            })
    }, [])


    useEffect(() => {
        setGate(null)
        setGateOptions([])

        if (site) {
            authAxios
                .post( GET_GATE_LIST_PATH, { aoi_site: site.id })

                // TODO: 
                // .get('https://run.mocky.io/v3/cbf49298-7c9c-470d-8b4b-71179d92df90')

                .then( res => {
                    const data = res.data
                    setGateOptions(data)

                    let lastSelectedGate = localStorage.getItem('lastSelectedGate')
                    if (lastSelectedGate) {
                        setGate(data.filter(option => option.name === lastSelectedGate)[0])
                    }
                })
                .catch( err => {
                    console.log(err.response)
                })
        }
    }, [site])


    useEffect(() => {
        setGateDevicesOptions([])
        setGateDevices(null)


        // TODO:    TILL THE BELOW API IS NOT WORKING, USE A TEST DATA
        // setGateDevicesOptions([
        //     { name: '60_parkbox' },
        //     { name: '58_android_tv' },
        //     { name: '23_jetson_logs' },
        //     { name: '23_jetson_ocr' },
        //     { name: '11:11:11:11:11:11' },
        //     { name: '100_parkbox' },
        // ])

        if (gate) {
            authAxios   
                .post(GET_TOPIC_LIST_PATH, { gate: gate.id })

                // TODO:
                // .get('https://run.mocky.io/v3/4e916d92-7556-4979-9f7c-e049bde5eb0c')

                .then( res => {
                    let data = res.data
                    let options = []

                    for (let curr_obj of data) {
                        let topics_list = curr_obj['topics_list']
                        
                        for (let topic of topics_list) {
                            options.push({
                                name: topic
                            })
                        }
                    }
                    setGateDevicesOptions(options)

                    let lastSelectedDevice = localStorage.getItem('lastSelectedDevice')
                    if (lastSelectedDevice) {
                        setGateDevices(options.filter(option => option.name === lastSelectedDevice)[0])
                    }
                })
                .catch( err => {
                    console.log(err.response)
                })
        }

        // TODO: SINCE GATE AND SITE IS NOT WORKING SO TEMPORARILY COMMENTING 
        }, [gate])
    // }, [])


    return (
        <div
            style={{
                marginLeft: 29,
                display: "flex",
                alignItems: "center",
            }}
        >
            <div style={{ flexGrow: 1 }}>
                <FormControl className={classes.formControl}>

                    <Autocomplete
                        size={"small"}
                        options={siteOptions}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 200 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="normal"
                                label="Site"
                                variant="outlined"
                            />
                        )}
                        value={site}
                        onChange={(event, value) => {
                            setSite(value)
                            localStorage.setItem('lastSelectedSite', value.name)
                        }}
                    />
                </FormControl>

                <FormControl className={classes.formControl}>
                    <Autocomplete
                        size={"small"}
                        options={gateOptions}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 200 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="normal"
                                label="Gate"
                                variant="outlined"
                            />
                        )}
                        value={gate}
                        onChange={(event, value) => {
                            setGate(value)
                            localStorage.setItem('lastSelectedGate', value.name)
                        }}
                    />
                </FormControl>

                <FormControl className={classes.formControl}>
                    <Autocomplete
                        size={"small"}
                        options={gateDevicesOptions}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 200 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="normal"
                                label="Topic"
                                variant="outlined"
                            />
                        )}
                        value={gateDevices}
                        onChange={(event, value) => {
                            setGateDevices(value)
                            localStorage.setItem('lastSelectedDevice', value.name)
                            // console.log(value, 'gd')
                            // NOTE:    if a user switch from topic1 to topic 2 and he has already selected some command which is at a index 
                            //          greater than the number of commands in topic2, we will get an error. So, whenever user changes the 
                            //          topic, we will reset selectedIndex value to 0
                            setSelectedIndex(0)
                        }}
                    />
                </FormControl>
            </div>

            <div style={{ margin: 5 }}>
                <ConnectionStatus />
            </div>

            <div>
                <EnableDisablePing />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="clear history">
                    <IconButton onClick={props.clearHistory}>
                        <GiBroom
                            aria-label="clear history"
                            // aria-aria-labelledby="clear history"
                        />
                    </IconButton>
                </Tooltip>
            </div>

            <div>
                <UserAvtar />
            </div>
        </div>
    )
}

const mapDispatchToProps = { clearHistory }


export default connect(null, mapDispatchToProps)(Toolbar)