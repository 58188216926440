import { VARYING, NON_VARYING } from '../Reusables/constants'
import { GenericCommands } from './GenericCommands'

import GetFasTagStatus from '../CommandsComponents/ParkboxNew/GetFasTagStatus'
import GetParkboxVersion from '../CommandsComponents/ParkboxNew/GetParkboxVersion'
import StartRemoteLog from '../CommandsComponents/ParkboxNew/StartRemoteLog'
import EndRemoteLog from '../CommandsComponents/ParkboxNew/EndRemoteLog'
import SetScanTime from '../CommandsComponents/ParkboxNew/SetScanTime'
import SetRfidPower from '../CommandsComponents/ParkboxNew/SetRfidPower'
import SetParkboxLogLevel from '../CommandsComponents/ParkboxNew/SetParkboxLogLevel'
import GetBankKey from '../CommandsComponents/ParkboxNew/GetBankKey'
import SetRfidBand from '../CommandsComponents/ParkboxNew/SetRfidBand'
import SearchSitePass from '../CommandsComponents/ParkboxNew/SearchSitePass'
import BarrierUp from '../CommandsComponents/ParkboxNew/BarrierUp'
import SetBaudRate from '../CommandsComponents/ParkboxNew/SetBaudRate'
import GetMemory from '../CommandsComponents/ParkboxNew/GetMemory'
import OTA from '../CommandsComponents/ParkboxNew/OTA'



const commands = [
    {
        label: "Get FasTag Status",
        component: GetFasTagStatus,
        commandType: VARYING,
    },
    {
        label: "Get Parkbox Version",
        component: GetParkboxVersion,
        commandType: VARYING,
    },
    {
        label: "Start Remote Log",
        component: StartRemoteLog,
        commandType: VARYING,
    },
    {
        label: "End Remote Log",
        component: EndRemoteLog,
        commandType: VARYING,
    },
    {
        label: "Set Scan Time", 
        component: SetScanTime,
        commandType: VARYING,
    },
    {
        label: "Set RFID Power", 
        component: SetRfidPower,
        commandType: VARYING,
    },
    {
        label: "Set Parkbox Log Level", 
        component: SetParkboxLogLevel,
        commandType: VARYING,
    },
    {
        label: "Get Bank Key",
        component: GetBankKey,
        commandType: VARYING,
    },
    {
        label: "Set RFID Band",
        component: SetRfidBand,
        commandType: VARYING,
    },
    {
        label: "Search Site Pass",
        component: SearchSitePass,
        commandType: VARYING,
    },
    {
        label: "Barrier UP",
        component: BarrierUp,
        commandType: VARYING,
    },
    {
        label: "Set Baud Rate",
        component: SetBaudRate,
        commandType: VARYING,
    },
    {
        label: "Get Memory",
        component: GetMemory,
        commandType: VARYING,
    },
    {
        label: "OTA",
        component: OTA,
        commandType: VARYING,
    }
    
]


const ParkboxNewCommands = GenericCommands.concat(commands)


export { ParkboxNewCommands }