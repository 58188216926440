
// NOTE:    This file will store all the constants like sender's / receiver's mac address,
//          repeated functions, message codes.

//          This will help us in saving our time, if we make any change in future.
//          And also help us resolve bugs fast.


// =======================================================
//      GLOBAL CONSTANTS AND FUNCTIONS
// =======================================================

// NOTE:    Path to receive the required list
export const GET_SITE_LIST_PATH = "/parkzap-pos/get_aoi_site_list/"
export const GET_GATE_LIST_PATH = "/parkzap-pos/get_gate_list/"
export const GET_TOPIC_LIST_PATH = "/parkzap/parkbox/get_parkbox_list/"

// NOTE:    Mac addresses which will be sent on message publish
export const SENDER_MAC_ADDRESS = "11:11:11:11:11:11"
export const RECEIVER_MAC_ADDRESS = "00:00:00:00:00:00"

// NOTE:    These are the constants for us to identify whether json to be sent is fixed or not.
export const VARYING = "VARYING"
export const NON_VARYING = "NON_VARYING"


// NOTE:    This will give us current EPOC time in seconds.
export const generateCurrentEpocTimeInSeconds = () => {
    return Math.round(Date.now() / 1000)
}



// =======================================================
//      ANDROID 
// =======================================================
export const CLEAR_CACHE_MESSAGE_CODE = 6006


// =======================================================
//      ANDROID TV 
// =======================================================
export const SCREEN_DECREASE_MESSAGE_CODE = 4000
export const SCREEN_INCREASE_MESSAGE_CODE = 4001
export const CHANGE_TEXT_TESTING_UNDER_MAINTENANCE_MESSAGE_CODE = 6030
export const CHANGE_TEXT_NORMAL_MESSAGE_CODE = 6031
export const BANNER_SHOW_MESSAGE_CODE = 6032
export const BANNER_HIDE_MESSAGE_CODE = 6033
export const DEBUG_SHOW_MESSAGE_CODE = 6034
export const DEBUG_HIDE_MESSAGE_CODE = 6035


// =======================================================
//      GENERIC 
// =======================================================
export const IS_ALIVE_MESSAGE_CODE = 1
export const GET_NETWORK_DETAILS_MESSAGE_CODE = 2
export const RESET_DEVICE_MESSAGE_CODE = 3
export const GET_LOCAL_MQTT_SERVER_MESSAGE_CODE = 4
export const TOPIC_SUBSCRIBER_DISCONNECTING_MESSAGE_CODE = 6
export const START_COPY_LOCAL_MESSAGES_TO_REMOTE_BROKER_MESSAGE_CODE = 7
export const STOP_COPY_LOCAL_MESSAGES_TO_REMOTE_BROKER_MESSAGE_CODE = 8


// =======================================================
//      GENERIC ANDROID
// =======================================================
export const SEND_LOGCAT_DETAILS_MESSAGE_CODE = 6050
export const START_TEAM_VIEWER_MESSAGE_CODE = 6003
export const STOP_LOGCAT_DETAILS_MESSAGE_CODE = 6051



// =======================================================
//      JETSON 
// =======================================================
export const GET_CURRENT_OCR_MESSAGE_CODE = 2002


// =======================================================
//      PARKBOX NEW
// =======================================================
export const GET_FASTAG_STATUS_MESSAGE_CODE = 8000
export const GET_PARKBOX_VERSION_MESSAGE_CODE = 8001
export const START_REMOTE_LOG_MESSAGE_CODE = 8002
export const END_REMOTE_LOG_MESSAGE_CODE = 8003
export const SET_SCAN_TIME_MESSAGE_CODE = 8004
export const SET_RFID_POWER_MESSAGE_CODE = 8005
export const SET_PARKBOX_LOG_LEVEL_MESSAGE_CODE = 8006
export const GET_BANK_KEY_MESSAGE_CODE = 8007
export const SET_RFID_BAND_MESSAGE_CODE = 8008
export const SEARCH_SITE_PASS_MESSAGE_CODE = 8009
export const BARRIER_UP_MESSAGE_CODE = 8010
export const SET_BAUD_RATE_MESSAGE_CODE = 8011
export const GET_MEMORY_MESSAGE_CODE = 8012
export const FASTAG_DETAILS_MESSAGE_CODE = 8013
export const OTA_MESSAGE_CODE = 8014





// =======================================================
//      GENERIC 
// =======================================================
export const GET_NETWORK_DETAILS_REPLY_CODE = 1002


// ==============================================================================
//      PARKBOX NEW -- REPLY CODE
// ==============================================================================
export const GET_FASTAG_STATUS_REPLY_CODE = 9000
export const GET_PARKBOX_VERSION_REPLY_CODE = 9001
export const START_REMOTE_LOG_REPLY_CODE = 9002
export const END_REMOTE_LOG_REPLY_CODE = 9003
export const SET_SCAN_TIME_REPLY_CODE = 9004
export const SET_RFID_POWER_REPLY_CODE = 9005
export const SET_PARKBOX_LOG_LEVEL_REPLY_CODE = 9006
export const GET_BANK_KEY_REPLY_CODE = 9007
export const SET_RFID_BAND_REPLY_CODE = 9008
export const SEARCH_SITE_PASS_REPLY_CODE = 9009
export const BARRIER_UP_REPLY_CODE = 9010
export const SET_BAUD_RATE_REPLY_CODE = 9011
export const GET_MEMORY_REPLY_CODE = 9012
export const FASTAG_DETAILS_REPLY_CODE = 9013
export const OTA_REPLY_CODE = 9014
