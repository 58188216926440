// LOG LEVEL OPTIONS
const ESP_LOGI = "ESP_LOGI" //  Log Information
const ESP_LOGE = "ESP_LOGE" // Log error
const ESP_LOGW = "ESP_LOGW" // Log warning
const ESP_LOGD = "ESP_LOGD" //Log debug
const ESP_LOGV = "ESP_LOGV" //Log Verbose
const ESP_LOGN = "ESP_LOGN" //Log None

// This array is for autocomplete component
const logLevelOptions = [
  { value: ESP_LOGI, title: "Information" },
  { value: ESP_LOGE, title: "Error" },
  { value: ESP_LOGW, title: "Warning" },
  { value: ESP_LOGD, title: "Debug" },
  { value: ESP_LOGV, title: "Verbose" },
  { value: ESP_LOGN, title: "None" },
]

// LOG TAG OPTIONS
// const TAG = "APP"
// const FLASH_TAG = "Flash"
// const MAIN_TAG = "Main"
// const ETHERNET_TAG = "ETHERNET"
// const SOCKET_TAG = "SOCKET"
// const SORT_TAG = "SORT DATA "
// const SMARTCONFIG_TAG = "Smart Config"
// const JSON_TAG = "JSON Data "
// const RELAY_TAG = "RELAY Data "
// const HTTP_TAG = "HTTP Data "
// const OTA_TAG = "OTA"
// const UART_TASK_TAG = "UART_TASK"
// const PARKBOX_STATUS_TASK_TAG = "PARKBOX_STATUS"
// const INPUT_TASK_TAG = "INPUT_TASK_TAG"
// const PROCESS_FASTAG_TAG = "PROCESS_FASTAG"
// const SITE_PASS_TAG = "SITE_PASS"
// const SITE_PASS_LOG_TAG = "SITE_PASS_LOG"
// const HTTP_CLIENT_TAG = "HTTP_CLIENT_T"
// const BARRIER_DEBUG_TAG = "BARRIER_DEBUG"

// const logTagOptions = [
//     { value: TAG, title: TAG },
//     { value: FLASH_TAG, title: FLASH_TAG },
//     { value: MAIN_TAG, title: MAIN_TAG},
//     { value: ETHERNET_TAG, title: ETHERNET_TAG},
//     { value: SOCKET_TAG, title: SOCKET_TAG},
//     { value: SORT_TAG, title: SORT_TAG},
//     { value: SMARTCONFIG_TAG, title: SMARTCONFIG_TAG},
//     { value: JSON_TAG, title: JSON_TAG},
//     { value: RELAY_TAG, title: RELAY_TAG},
//     { value: HTTP_TAG, title: HTTP_TAG},
//     { value: OTA_TAG, title: OTA_TAG},
//     { value: UART_TASK_TAG, title: UART_TASK_TAG},
//     { value: PARKBOX_STATUS_TASK_TAG, title: UART_TASK_TAG},
// ]

const logTagsOptions = [
  "*",
  "APP",
  "Flash",
  "Main",
  "ETHERNET",
  "SOCKET",
  "SORT DATA ",
  "Smart Config",
  "JSON Data ",
  "RELAY Data ",
  "HTTP Data ",
  "OTA",
  "UART_TASK",
  "PARKBOX_STATUS",
  "INPUT_TASK_TAG",
  "PROCESS_FASTAG",
  "SITE_PASS",
  "SITE_PASS_LOG",
  "HTTP_CLIENT_T",
  "BARRIER_DEBUG",
  "REMOTE_LOG_TAG"
].map((item) => ({ title: item, value: item }))

export { logLevelOptions, logTagsOptions, ESP_LOGI }
