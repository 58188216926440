import React, { useState } from 'react'

import { useMqttClientContext } from '../../../Contexts/mqttClientContext/mqttClientContext'

import RenderJsonAndButton from '../../Reusables/RenderJsonAndButton'

import TextField from "@material-ui/core/TextField"



const CustomCommand = ({ topic }) => {

    const client = useMqttClientContext()

    const [customCommandInput, setCustomCommandInput] = useState(null)


    const generateJsonCommand = customCommand => {
        return customCommand
    }

    const handleSend = () => {
        let JSONCommand = JSON.stringify(JSON.parse(generateJsonCommand(customCommandInput)))

        client.publish(topic, JSONCommand, {}, (err) => {
            if (err) {
                alert(err)
            }
        })
    }

    const inputFields = () => (
        <div style={{ flexGrow: 1 }}>
            {/* here goes input field */}
            <TextField
                id="standard-basic"
                label="Custom Command"
                multiline={true}
                value={customCommandInput}
                onChange={(event) => setCustomCommandInput(event.target.value)}
                placeholder={"Enter custom command here..."}
            />
        </div>
    )


    return (
        <RenderJsonAndButton
            topic={topic}
            jsonCommand={generateJsonCommand()}
            handleSend={handleSend}
            inputFields={inputFields}
        />
    )
}


export default CustomCommand