import React from "react"
import { Provider } from "react-redux"

import { client } from "./Contexts/mqttClientContext/mqttClientContext"

import createMqttClientContext from "./Contexts/mqttClientContext/mqttClientContext"
// import MqttWrapper from "./components/mqtt"
import Panel from './components/Panel'
import store from "./redux/store"


const MqttContext = createMqttClientContext()


// NOTE:    This is a wrapper which pass the redux store and a function returned after we connect to the broker to all the components
//            inherited from this mqttWrapper component. so we don't need to manually import these to every component.
const AppRenderer = () => {
  
  return (
    <MqttContext.Provider value={client}>
      <Provider store={store}>

        {/* <MqttWrapper /> */}
        <Panel />

      </Provider>
    </MqttContext.Provider>
  )
}

export default AppRenderer
