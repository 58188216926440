import { combineReducers } from "redux"

import messageReducer from "./mqttMessages/MessageReducer"
import displayCodeReducer from './DisplayMessageCodes/DisplayCodeReducer'


const mqttReducer = combineReducers({
  messageReducer,
  displayCodeReducer
})


export default mqttReducer