import React, { useState, useEffect } from "react"

// import { commands } from "../Commands/ParkboxCommands"

import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { makeStyles } from "@material-ui/core/styles"


const useStylesBox = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: 500,
		display: "flex",
		flexDirection: "column",
		padding: 24,
		boxSizing: "border-box",
		overflow: "hidden",
	},
}))

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		height: 500,
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
		width: 200,
		minWidth: 200,
		maxWidth: 200,
	},
}))


function renderComponent(Component, props) {
	if (!Component) return
	return <Component {...props} />
}


const VerticalTabs = ({
	parkbox,
	parkboxOptions,
	selectedDeviceCommands,
	topic,
	selectedIndex,
	setSelectedIndex,
	...props
}) => {

	const classes = useStyles()


	return (
		<div className={classes.root}>
			{/* {console.log(props, "of tabs")} */}

			{/* NOTE: 	To render all the commands of the selected device. */}
			<Tabs
				orientation="vertical"
				value={selectedIndex}
				onChange={(e, index) => setSelectedIndex(index)}
				className={classes.tabs}
				variant="scrollable"
			>
				{
					selectedDeviceCommands.map((item, index) => (
						<Tab label={item.label} key={item.label} value={index} />
					))
				}
			</Tabs>

			{
				renderComponent(selectedDeviceCommands[selectedIndex]["component"], {
					topic: topic,
					// NOTE: 	This will be used in non-varying command type else it won't be.
					command: selectedDeviceCommands[selectedIndex]["command"],
				})
			}
		</div>
	)
}


export default VerticalTabs