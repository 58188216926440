import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import axios from "axios"

import baseUrl from "../../APIList"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Container from "@material-ui/core/Container"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles } from "@material-ui/core/styles"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import FormControlLabel from "@material-ui/core/FormControlLabel"


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))


function Copyright() {

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}

            <Link color="inherit" href="https://parkzap.com/">
                Parkzap
            </Link>{" "}

            {new Date().getFullYear()}
            {"."}

            <div>
                <img style={{ height: 20 }} src="parkzaplogo.svg" alt="logo" />
            </div>
        </Typography>
    )
}


export default function SignIn() {

    const classes = useStyles()
    const history = useHistory()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = (event, b, c) => {
        // NOTE:    To prevent reloading
        event.preventDefault()

        setIsSubmitting(true)

        const formData = new FormData(event.target)
        let isRememberMeChecked = formData.get("remember-me")

        axios
            .post(`${baseUrl}/token/auth/`, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                // setIsSubmitting(false)

                // NOTE:    If user wants itself to be remembered even after he close the tab, we store the token in 
                //          localstorage else we store the token in session ( which will be deleted if user closes the tab). 
                if (isRememberMeChecked) {
                    localStorage.setItem("mqttDashboardToken", res.data.token)
                } else {
                    sessionStorage.setItem("mqttDashboardToken", res.data.token)
                }

                // NOTE:    To redirect to '/' path if the credentials are truthy.
                history.push("/")
            })
            .catch((err) => {
                let errorObject = err.toJSON()

                let errorMessage =
                    // err.response && err.response.data && err.response.data.error_message
                    err.response?.data?.error_message
                        ? err.response.data.error_message
                        : errorObject.message || "Some error ocurred. Please try again."

                setIsSubmitting(false)

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="username"
                        autoComplete="email"
                        autoFocus
                        disabled={isSubmitting}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        disabled={isSubmitting}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="remember"
                                color="primary"
                                disabled={isSubmitting}
                                name="remember-me"
                            />
                        }
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting}
                    >
                        Sign In
                    </Button>
                </form>
            </div>

            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    )
}
