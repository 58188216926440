
import {
    UPDATE_CHECKBOXES,
    ADD_NEW_KEY
} from './DisplayCodeAction';
// import {
//     GET_NETWORK_DETAILS_REPLY_CODE,
//     GET_FASTAG_STATUS_MESSAGE_CODE,
//     FASTAG_DETAILS_MESSAGE_CODE,
//     GET_FASTAG_STATUS_REPLY_CODE,
//     FASTAG_DETAILS_REPLY_CODE,
// } from '../../components/Reusables/constants'



const initialState = {
    // [GET_NETWORK_DETAILS_REPLY_CODE]: true,
    // [GET_FASTAG_STATUS_MESSAGE_CODE]: true,
    // [FASTAG_DETAILS_MESSAGE_CODE]: true,
    // [GET_FASTAG_STATUS_REPLY_CODE]: true,
    // [FASTAG_DETAILS_REPLY_CODE]: true,
}


function displayCodeReducer(state = initialState, action) {

    switch (action.type) {
        case UPDATE_CHECKBOXES:

            return {
                ...state,
                [action.name]: action.checked
            }

        case ADD_NEW_KEY:

            return {
                ...state,
                [action.name]: true
            }

        default:
            return state
    }
}


export default displayCodeReducer